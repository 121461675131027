$font-family-rubik: 'Rubik', sans-serif;
$font-family-ruda: 'Ruda', sans-serif;

// Font sizes
$fs-60: 3.75rem;
$fs-48: 3rem;
$fs-42: 2.625rem;
$fs-36: 2.25rem;
$fs-32: 2rem;
$fs-28: 1.75rem;
$fs-24: 1.5rem;
$fs-20: 1.25rem;
$fs-18: 1.125rem;
$fs-17: 1.0625rem;
$fs-16: 1rem;
$fs-14: 0.875rem;
$fs-12: 0.75rem;
$fs-10: 0.625rem;
$fs-8: 0.5rem;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

@mixin heading-h1 {
  font-family: $font-family-ruda;
  font-size: $fs-32;
  font-weight: $fw-black;
  line-height: 1.25em;
}

@mixin heading-h2 {
  font-family: $font-family-ruda;
  font-size: $fs-24;
  font-weight: $fw-black;
  line-height: 1.5em;
}

@mixin heading-h3 {
  font-family: $font-family-ruda;
  font-size: $fs-16;
  font-weight: $fw-black;
  line-height: 1.5em;
}
