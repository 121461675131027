@import "@shared/styles/design-system/index";

.inputMessage {
  font-family: $font-family-rubik;
  font-size: $fs-14;
  font-weight: 400;
  line-height: 1.0625rem;
  color: $grey-500;
  margin: 0;
  padding: 5px 15px 0;
}
