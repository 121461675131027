@import "@shared/styles/design-system/index";

.wrapper {
  position:fixed;
  bottom: 50px;
  left: 20px;
  right: 20px;
  background: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  overflow: hidden;
}

.linksList {
  justify-content: space-between;
  display: flex;
  padding: 0 25px;
  margin: 0;
  height: 60px;
  list-style: none;
  align-items: stretch;
}

.linkWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  flex: 1;
  transition: transform 0.1s ease-out;

  &.addNewEntry {
    background-color: #DF00FF;
    height: auto;
    padding: 8px;
    border-radius: 15px;
  }

  &.isActive {
    position: relative;
    transform: translateY(-7px);

    .linkTitle {
      opacity: 1;
      transform: translateY(0);

    }
  }
}

.linkTitle {
  position: absolute;
  top: calc(100% + 2px);
  font-size: $fs-10;
  line-height: 1em;
  text-decoration: none;
  color: #000;
  transition: opacity 0.2s ease-out, transform 0.2s ease-in-out;
  opacity: 0;
  transform: translateY(3px);
}
