@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ruda:wght@400;500;600;700;800;900&display=swap');

@import "@shared/styles/design-system/index";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #F7F7F7;
}

body,
button,
textarea,
input {
  font-family: $font-family-rubik;
}
