@import "@shared/styles/design-system/index";

.form {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.logoWrapper {
  padding: 50px 0 0;
  text-align: center;

  .logo {
    font-size: 60px;
    margin: 0;
    color: #FEC300;
    letter-spacing: -0.1em;
    line-height: 1em;
  }
}

.link {
  text-align: center;
  margin-top: 25px;
  display: block;
}

.title {
  text-align: center;
}

.actionsWrapper {
  padding-bottom: 50px;
}
