@import "@shared/styles/design-system/index";

.h1 {
  @include heading-h1;
}

.h2 {
  @include heading-h2;
}

.h3 {
  @include heading-h3;
}
