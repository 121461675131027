@import "@shared/styles/design-system/index";

.wrapper {
  border: none;
  padding: 15px 30px;
  border-radius: 15px;
  font-size: $fs-18;
  font-weight: $fw-medium;
  line-height: 1em;
  font-family: $font-family-rubik;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &.primary {
    color: #fff;
    background-color: #C200EF;

    &:hover {
      background-color: #d833ff;
    }

    &:disabled {
      background-color: #bdbdbd;
      color: #888888;
    }
  }

  &.secondary {
    color: #C200EF;
    background-color: rgba(#C200EF, 0.05);

    &:hover {
      background-color: rgba(#C200EF, 0.1);
    }

    &:disabled {
      background-color: rgba(#9b9b9b, 0.05);
      color: #9b9b9b;
    }
  }
}
