$white: #ffffff;
$grey-25: #fafbfc;
$grey-50: #f8fafa;
$grey-100: #f4f5f6;
$grey-150: #edeeef;
$grey-200: #e4e4e4;
$grey-300: #bfbfbf;
$grey-400: #9e9e9e;
$grey-500: #6b6b6b;
$grey-550: #575757;
$black: #000000;

$blue-dark-100: #231ca7;
$blue: #2b2bd4;
$blue-light-100: #5353e1;
$blue-light-200: #a5a5fc;
$blue-light-300: #d0d0ff;
$blue-light-400: #dedeff;

$pink-dark-350: #a743a7;
$pink-dark-300: #aa4eaa;
$pink-dark-200: #d178d1;
$pink-dark-100: #f0b3f0;
$pink: #fad2fa;
$pink-light-100: #ffdeff;
$pink-light-200: #fde7fd;

$red-light-100: #EB6060;
$red-light-200: #FDF3F3;

$green-dark-300: #377772;
$green-dark-200: #4bc7bc;
$green-dark-100: #90f5ec;
$green: #c8fffa;
$green-light-100: #e1fdfb;

$taupe-dark-300: #c3b190;
$taupe-dark-200: #b19675;
$taupe-dark-100: #d0bfa5;
$taupe: #f0e7d6;
$taupe-light-100: #fcf3e8;

$system-red: #d20e0e;
$system-green: #108723;
$system-yellow: #f2df34;

@mixin gradient-green-pink(
  $angle: 162.46deg,
  $first-stop: 0%,
  $second-stop: 89.83%
) {
  background-image: linear-gradient(
    $angle,
    $green $first-stop,
    $pink $second-stop
  );
}

@mixin radient-pink-taupe(
  $angle: 162.46deg,
  $first-stop: 0%,
  $second-stop: 64.83%
) {
  background-image: linear-gradient(
    $angle,
    $pink $first-stop,
    $taupe $second-stop
  );
}

@mixin gradient-green-taupe(
  $angle: 162.46deg,
  $first-stop: 0%,
  $second-stop: 72.65%
) {
  background-image: linear-gradient(
    $angle,
    $green $first-stop,
    $taupe $second-stop
  );
} ;
