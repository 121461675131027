@import "@shared/styles/design-system/index";

.label {
  width: 100%;
}

.withMargin {
  margin-bottom: 20px;
}

@mixin textStyles() {
  font-family: $font-family-rubik;
  font-size: $fs-17;
  line-height: 1em;
  font-weight: $fw-light;
}

.input {
  width: 100%;
  padding: 13px 15px;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 15px;
  color: #2D4F70;
  background-color: #FFFFFF;
  vertical-align: middle;
  transition: border .15s ease;

  @include textStyles;

  &::placeholder {
    @include textStyles;

    color: #B0B0B0;
  }

  &:hover {
    background-color: #F4FEFF;
  }

  &:focus {
    outline: none;
    border: 1px solid #00E8F9;
    background-color: #F4FEFF;
  }

  &:disabled {
    background-color: #F4F4F4;
    color: #9A9A9A;

    &:hover {
      background-color: #F4F4F4;
    }
  }

  &.isInvalid {
    color: $system-red;
    border: 1px solid $system-red;
    background-color: #ffeeee;

    &:focus {
      border: 1px solid $system-red;
    }
  }
}

.messageWrapper {
  padding: 0 15px;
}
